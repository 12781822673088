import { useState, useEffect } from "react";

const useDocumentDimensions = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onResize = () => {
    const {
      documentElement: { offsetWidth, offsetHeight },
    } = document;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return [width, height];
};

export default useDocumentDimensions;
