import PropTypes, { element } from "prop-types";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import useDocumentDimensions from "helpers/useDocumentDimensions";
import Header from "components/Header";
import Footer from "components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { useStaticQuery, graphql } from "gatsby";

//eslint-disable-next-line
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import storageAvailable from "helpers/storageAvailable";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollSmoother);

gsap.core.globals("ScrollTrigger", ScrollTrigger);

const Layout = ({ children }) => {
  const ref = useRef(null);
  const footerRef = useRef();
  const headerRef = useRef();
  const pathRefMobile = useRef();
  const pathRefDesktop = useRef();
  const [width, height] = useDocumentDimensions();
  const [showBlogNotification, setShowBlogNotification] = useState(false);
  const {
    allContentfulBlogPost: {
      nodes: [lastPost],
    },
  } = useStaticQuery(GET_LAST_POST_MAIN);
  useEffect(() => {
    if (!storageAvailable) {
      console.log("main - storage not available");
      return;
    }

    if (!localStorage.getItem("last-post")) {
      console.log("main - last-post is not set");
      setShowBlogNotification(true);
      return;
    }

    if (localStorage.getItem("last-post") !== lastPost.id) {
      console.log(
        "main - last-post is different from last",
        localStorage.getItem("last-post"),
        lastPost.id
      );
      setShowBlogNotification(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (!headerRef || !headerRef.current) return;

    const element = headerRef.current;
    const menuAnchors = element.querySelectorAll("a");

    const showAnim = gsap
      .from(element, {
        yPercent: -200,
        paused: true,
        duration: 0.1,
      })
      .progress(1);

    const showAnchors = gsap
      .from(menuAnchors, {
        autoAlpha: 0,
        yPercent: 10,
        paused: true,
        stagger: 0.1,
      })
      .progress(1);

    gsap.to(element, {
      scrollTrigger: {
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1
            ? showAnim.play() && showAnchors.play()
            : showAnim.reverse() && showAnchors.reverse();
        },
      },
    });
  }, []);

  useLayoutEffect(() => {
    const element = ref.current;
    const footer = footerRef.current;

    if (!element || !footer) return;
    const footerHide = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: element,
        start: "bottom bottom",
        endTrigger: footer,
        end: "bottom bottom",
        scrub: true,
      },
    });
    footerHide.fromTo(
      footer.querySelector("section"),
      {
        yPercent: "-35",
      },
      {
        yPercent: 0,
        ease: "none",
      }
    );
  });

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      wrapper: "#___gatsby",
    });
  });

  useLayoutEffect(() => {
    if (!ref || !pathRefMobile || !pathRefDesktop) return;

    const mainElement = ref.current;
    const elementMobile = pathRefMobile.current;
    // const elementDesktop = pathRefDesktop.current;

    const pathMobile = elementMobile.querySelector("path");

    // const pathDesktop = elementDesktop.querySelector("path");

    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({
      "(max-width: 799px)": function () {
        const pathTl = gsap.timeline({
          scrollTrigger: {
            trigger: mainElement,
            start: "top top",
            end: "bottom bottom",
            scrub: true,
            markers: false,
          },
        });
        pathTl.fromTo(
          pathMobile,
          { drawSVG: "0% 0%", ease: "none" },
          { drawSVG: "20% 100%", ease: "none" },
          "0"
        );
      },
      // "(min-width: 800px)": function () {
      //   const pathTl = gsap.timeline({
      //     scrollTrigger: {
      //       trigger: mainElement,
      //       start: "top top",
      //       end: "bottom 120%",
      //       scrub: true,
      //       markers: false,
      //     },
      //   });
      //   pathTl.fromTo(
      //     pathDesktop,
      //     { drawSVG: "0% 0%", ease: "easein" },
      //     { drawSVG: "20% 100%", ease: "easeout" },
      //     "0"
      //   );
      // },
    });

    //   // pathTl.fromTo(
    //   //   path,

    //   //   {
    //   //     drawSVG: "0% 100% live",
    //   //   },
    //   //   {
    //   //     drawSVG: "100% 100% live",
    //   //   }
    //   // );
  }, [ref, pathRefMobile, pathRefDesktop]);

  return (
    // <div className="flex w-screen flex-col mx-auto" ref={ref}>
    <>
      <Header
        ref={headerRef}
        showBlogNotification={showBlogNotification}
        setShowBlogNotification={setShowBlogNotification}
        lastPost={lastPost}
      />
      <main className="flex-1 mx-auto w-screen  z-60" ref={ref}>
        {children}
      </main>
      {/* <div
        className="hidden md:block absolute top-72 z-20"
        ref={pathRefDesktop}
      >
        <svg
          width={width}
          height={height - (height * 10) / 100}
          viewBox={`0 0 ${width} ${height - (height * 10) / 100}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="10px"
            stroke="#ff1d4d8a"
            d="M595.625 2.95996C183.427 554.691 190.534 692.625 318.457 955.354C446.381 1218.08 887.007 1441.4 659.587 1730.4C432.168 2019.41 -86.6322 2117.94 176.321 2459.47C439.274 2801.02 27.0769 2630.25 62.6111 2860.15C98.1454 3090.03 339.779 3044.06 446.381 2892.99C552.985 2741.91 659.587 2814.16 659.587 2892.99C659.587 2971.8 688.014 3122.87 503.236 3273.94C318.457 3425.01 118.932 3570.78 190 3833.5C261.069 4096.22 528.111 3965.25 620.5 4326.5C712.889 4687.75 552.985 4830.61 396.634 4968.54C240.284 5106.48 -32 5195 -14 5417C4 5639 133.679 5737.02 318.457 5881.53C503.237 6026.03 815.938 6113.87 659.587 6310.1C503.236 6506.32 -51.099 6395.49 62.6111 6682.84C176.321 6970.21 688.014 6584.33 595.625 7003.05C503.236 7421.77 -104.5 6521 62.6111 7454.5C282.924 7997.18 947.414 7924.24 595.625 8270.72C243.836 8617.2 91.0386 8657.42 243.836 8892.23C366.073 9080.08 467.701 9166.47 503.236 9186.17"
          />
        </svg>
      </div> */}
      <div className="block md:hidden absolute top-0 z-20" ref={pathRefMobile}>
        <svg
          width={width}
          height={height - (height * 5) / 100}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="10px"
            d="M333.972 2C82.8845 338.03 87.2136 422.038 165.137 582.052C243.061 742.066 511.465 878.078 372.934 1054.09C234.403 1230.11 -81.6204 1290.12 78.5559 1498.13C238.732 1706.15 -12.3553 1602.14 9.29013 1742.16C30.9356 1882.17 178.125 1854.17 243.061 1762.16C307.998 1670.15 372.934 1714.15 372.934 1762.16C372.934 1810.16 390.25 1902.17 277.694 1994.18C165.137 2086.19 35.2652 2078.19 78.5559 2238.2C121.847 2398.21 221.416 2358.21 277.694 2578.23C333.972 2798.25 307.998 2942.26 212.758 3026.27C117.518 3110.28 -34.0009 3206.29 9.29013 3334.3C52.5812 3462.31 52.5806 3494.31 165.137 3582.32C277.694 3670.33 468.174 3723.83 372.934 3843.34C277.694 3962.85 -59.9756 3895.35 9.29013 4070.36C78.5559 4245.38 390.25 4010.36 333.972 4265.38C277.694 4520.4 -55.6457 4205.38 78.5559 4535.91C212.758 4866.43 548.262 4826.43 333.972 5037.45C119.682 5248.47 26.6065 5272.97 119.682 5415.98C194.142 5530.39 256.048 5583 277.694 5595"
            stroke="#ff1d4d8a"
          />

          {/* <path
            d="M317.114 5586C53.2028 5282 47.2048 5234 161.166 5114C275.128 4994 353.101 5082 281.125 4882C209.15 4682 -66.7573 4546 47.2045 4394C161.166 4242 149.171 4338 233.142 4394C317.114 4450 425.077 4418 413.081 4322C401.085 4226 305.118 4090 233.142 4098C161.166 4106 -66.7573 3962 47.2045 3882C161.166 3802 329.109 3866 281.125 3522C233.142 3178 65.1981 3338 113.182 3050C161.166 2762 281.125 3034 281.125 2754C281.125 2474 209.15 2418 161.166 2258C113.182 2098 149.171 2002 233.142 1922C317.114 1842 401.085 1690 317.114 1666C233.142 1642 275.128 1794 161.166 1810C47.2045 1826 -66.7573 1722 47.2045 1618C161.166 1514 245.137 1698 281.125 1506C317.114 1314 275.128 1218 161.166 1146C47.2045 1074 -6.77774 866 113.182 786C233.142 706 317.114 658 317.114 466C317.114 312.4 213.149 92.6667 161.166 2"
            stroke="#FF1D4D"
          /> */}
        </svg>
      </div>
      <Footer ref={footerRef} />
    </>
    // </div>
  );
};

const GET_LAST_POST_MAIN = graphql`
  query getLastPostMain {
    allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }
      limit: 1
    ) {
      nodes {
        id
      }
    }
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
